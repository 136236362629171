import React, {useContext} from 'react';
import Context from '../../context/ContextProvider';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

import './Botones.scss'

const DibujoPrueba = () => {

    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;        
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };

    
    return (
        <div className='div-dibujo'>
        <svg    
            version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px"
            width="607px" height="768px" 
            viewBox="0 0 607 768" 
            onClick={obtenetID}
        >
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M166.85,602.85
                    c3.84-6.33,7.45-12.14,10.74-17.19c11.74-18.05,29.26-41.03,29.26-41.03l-9.25-6.49l-41.23-28.92c0,0-7.99,12.41-17.29,25.76
                    c-3.71,5.33-7.63,10.81-11.33,15.72C99.9,587.55,36.81,657.74,21.66,679.34c-4.76,6.78-8.41,12.81-11.01,18.34
                    c-7.44,15.87-6.03,27.58,3.82,40.91c3.5,4.73,19.34,15.84,24.98,17.52c21.42,6.38,34.85-0.67,53.27-26.92
                    C105.9,710.41,141.28,645.04,166.85,602.85z"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M259.35,587.96
                    C201.79,555.34,148,517.61,97.75,474.59c-24.09-20.62-31.97-54.37-13.45-80.78L324.34,51.63C355.21,7.62,412.66,0.7,458.56,26.88
                    c35.19,20.07,67.86,43.5,98.95,69.42c40.6,33.83,53.29,90.62,22.42,134.63L339.89,573.11
                    C321.36,599.52,286.94,603.59,259.35,587.96z"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M249.33,569.07
                    c-50.28-26.6-96.15-58.92-139.09-97.57c-23.57-21.21-31.97-54.37-13.45-80.78L327.73,61.53c30.88-44.01,87.53-49.51,134.23-24.75
                    c27.84,14.76,53.08,32.47,76.43,53.62c39.18,35.48,53.29,90.62,22.42,134.63L329.87,554.22
                    C311.34,580.63,277.36,583.9,249.33,569.07z"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M181.89,425.51L181.89,425.51
                    c-11.73-8.23-14.57-24.42-6.34-36.15l198.89-283.52c8.23-11.73,24.42-14.57,36.15-6.34l0,0c11.73,8.23,14.57,24.42,6.34,36.15
                    L218.04,419.17C209.81,430.91,193.63,433.74,181.89,425.51z"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M273.38,490.52L273.38,490.52
                    c-11.73-8.23-14.57-24.42-6.34-36.15l198.89-283.52c8.23-11.73,24.42-14.57,36.15-6.34l0,0c11.73,8.23,14.57,24.42,6.34,36.15
                    L309.53,484.18C301.3,495.92,285.11,498.75,273.38,490.52z"/>
                <path fill="#FFFFFF" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M269.69,128.7l54.26-77.34
                    c30.88-44.01,88.32-50.93,134.23-24.75c35.19,20.07,67.86,43.5,98.95,69.42c40.6,33.83,53.29,90.62,22.42,134.63
                    c0,0-34.95,49.64-54.26,77.34c-21.24-2.4-64.78,62.45-59.07-11.73c2.71-35.26-8.31-48.79-21.42-50.1
                    c-14.48-1.45-54.29,54.84-76.2,52.92c-21.03,0.32-38.48-15.94-19.18-65.76c35.34-91.22-22.73-67.55-51.76-56.44
                    S269.69,128.7,269.69,128.7z"/>
            </g>
            
        </svg>             

            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default DibujoPrueba